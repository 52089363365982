import React from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { IArticle, IArticleFetched } from '../../models/article';
import rest from '../../rest/rest';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormErrors } from '../../actions/formError.actions';
import SelectCategory from '../data/SelectCategory';
import SelectProgram from '../data/SelectProgram';
import SelectStorageAreas from '../data/SelectStorageAreas';
import SelectPackagingType from '../data/SelectPackagingType';
import SelectTags from '../data/SelectTags';
import FileUploadButton, { formatBytes } from '../FileUploadButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import moment from 'moment';
import { FileFetched } from '../../models/file';
import OpenFile from '../OpenFile';
import ImagePreview from '../ImagePreview';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import SelectTenant from '../data/SelectTenant';

interface ArticleDialogProps {
    onFinished: () => void,
    children: any,
    initialData?: IArticleFetched,
    readOnly?: boolean,
}

const initialState: IArticle = {
    name: '',
    indoor: true,
    storage_areas: [],
    tags: [],
    production_price: undefined,
    selling_price: undefined,
    cost_factor: undefined,
    plain_height: undefined,
    plain_width: undefined,
    plain_depth: undefined,
    plain_weight: undefined,
    packaged_height: undefined,
    packaged_width: undefined,
    packaged_depth: undefined,
    packaged_weight: undefined,
    amount_total: 0,
    amount_today: 0,
    amount_per_package: undefined,
    description: '',
    seasonal: false,
    merch: true,
    production_date: null,
    active: true,
    files: [],
};

const steps: string[] = [
    'Allgemein',
    'Preise',
    'Maß & Gewicht',
    'Artikeldetails',
    'Dokumente',
    'Zusammenfassung',
];

function ArticleDialog({ onFinished, children, initialData, readOnly }: ArticleDialogProps) {
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer).errors;
    const [open, setOpen] = React.useState<boolean>(false);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(initialData ? steps.length - 1 : 0);
    const [data, setData] = React.useState<IArticle>(initialData ? initialData : initialState);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const nextPage = () => setPage(page + 1);

    const previousPage = () => setPage(page - 1);

    const handleSubmit = () => {
        setProcessing(true);
        dispatch(clearFormErrors());

        const mappedData = {
            ...data,
            category_id: data.category?.id,
            program_id: data.program?.id,
            tenant_id: data.tenant?.id,
            storage_area_ids: data.storage_areas?.map(area => area.id!) ?? [],
            packaging_id: data.packaging_type?.id,
            tag_ids: data.tags?.map(tag => tag.id!) ?? [],
        }

        if (!initialData) {
            rest.post('article/articles', mappedData)
                .then(async (res: any) => {
                    if (data.files.length > 0) {
                        console.log('uploading files ...');
                        const start = Date.now();

                        try {
                            await Promise.all(
                                data.files.map((file: File) => {
                                    const formData = new FormData();
                                    formData.append('file', file);

                                    return rest.post(`article/articles/${res.data.id}/file`, formData);
                                })
                            );
                        } catch (ex) {
                            // TODO: handle
                            console.error(ex);
                        }

                        console.log(`uploading files finished in ${Date.now() - start}ms`);
                    }

                    setProcessing(false);
                    handleClose();
                    setData(initialState);
                    setPage(0);
                    onFinished();
                })
                .catch(err => {
                    setProcessing(false);
                    console.error(err);
                });
            return;
        }

        const updated: any = Object.assign({}, mappedData);

        if (updated.name === initialData.name) {
            delete updated.name;
        }

        rest.patch(`article/articles/${data.id}`, updated)
            .then(async () => {
                if (data.files.length > 0) {
                    console.log('uploading files ...');
                    const start = Date.now();

                    try {
                        await Promise.all(
                            data.files
                                .filter((file: FileFetched) => !file.id)
                                .map((file: File) => {
                                        const formData = new FormData();
                                        formData.append('file', file);

                                        return rest.post(`article/articles/${data.id}/file`, formData);
                                })
                        );
                    } catch (ex) {
                        // TODO: handle
                        console.error(ex);
                    }

                    console.log(`uploading files finished in ${Date.now() - start}ms`);
                }

                setProcessing(false);
                handleClose();
                setPage(5);
                onFinished();
            })
            .catch(err => {
                setProcessing(false);
                console.error(err);
            });
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Artikel hinzufügen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Stepper activeStep={page} alternativeLabel>
                                {steps.map((name, index) => (
                                    <Step key={name} style={{ cursor: 'pointer' }} onClick={() => setPage(index)}>
                                        <StepLabel>{name}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12}>
                            {page === 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Artikelbezeichnung"
                                            size="small"
                                            value={data.name}
                                            onChange={event => setData({ ...data, name: event.target.value })}
                                            error={Boolean(formErrors.name)}
                                            helperText={formErrors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="u!s ID"
                                            size="small"
                                            value={data.zdf_id}
                                            onChange={event => setData({ ...data, zdf_id: event.target.value })}
                                            error={Boolean(formErrors.zdf_id)}
                                            helperText={formErrors.zdf_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="select-indoor-outdoor"
                                                required
                                                size="small"
                                                value={data.indoor ? 1 : 2}
                                                onChange={event => setData({ ...data, indoor: event.target.value === 1 })}
                                                error={Boolean(formErrors.indoor)}
                                            >
                                                <MenuItem value={1}>Indoor</MenuItem>
                                                <MenuItem value={2}>Outdoor</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="select-status"
                                                required
                                                size="small"
                                                value={data.active ? 1 : 2}
                                                onChange={event => setData({ ...data, active: event.target.value === 1 })}
                                            >
                                                <MenuItem value={1}>Online (aktiviert)</MenuItem>
                                                <MenuItem value={2}>Offline (deaktiviert)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectCategory
                                            onChange={category => setData({ ...data, category })}
                                            value={data.category?.id}
                                            error={formErrors.category_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectProgram
                                            onChange={program => setData({ ...data, program })}
                                            value={data.program?.id}
                                            error={formErrors.program_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectTenant
                                            required
                                            onChange={tenant => setData({ ...data, tenant })}
                                            value={data.tenant?.id}
                                            error={formErrors.tenant_id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectStorageAreas
                                            onChange={storageAreas => setData({ ...data, storage_areas: storageAreas })}
                                            values={data.storage_areas?.map(area => area.id!) ?? []}
                                            error={formErrors.storage_area_ids}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {page === 1 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Produktionspreis"
                                            size="small"
                                            type="number"
                                            value={data.production_price}
                                            onChange={event => setData({ ...data, production_price: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.production_price)}
                                            helperText={formErrors.production_price}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Einkaufspreis"
                                            size="small"
                                            type="number"
                                            value={data.selling_price}
                                            onChange={event => setData({ ...data, selling_price: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.selling_price)}
                                            helperText={formErrors.selling_price}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Mieteinheit"
                                            type="number"
                                            size="small"
                                            value={data.cost_factor}
                                            onChange={event => setData({ ...data, cost_factor: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.cost_factor)}
                                            helperText={formErrors.cost_factor}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {page === 2 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Ohne Verpackung</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Höhe"
                                            type="number"
                                            size="small"
                                            value={data.plain_height}
                                            onChange={event => setData({ ...data, plain_height: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.plain_height)}
                                            helperText={formErrors.plain_height}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Breite"
                                            type="number"
                                            size="small"
                                            value={data.plain_width}
                                            onChange={event => setData({ ...data, plain_width: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.plain_width)}
                                            helperText={formErrors.plain_width}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Tiefe"
                                            type="number"
                                            size="small"
                                            value={data.plain_depth}
                                            onChange={event => setData({ ...data, plain_depth: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.plain_depth)}
                                            helperText={formErrors.plain_depth}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Gewicht"
                                            type="number"
                                            size="small"
                                            value={data.plain_weight}
                                            onChange={event => setData({ ...data, plain_weight: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.plain_weight)}
                                            helperText={formErrors.plain_weight}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} />
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Mit Verpackung</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Höhe"
                                            type="number"
                                            size="small"
                                            value={data.packaged_height}
                                            onChange={event => setData({ ...data, packaged_height: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.packaged_height)}
                                            helperText={formErrors.packaged_height}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Breite"
                                            type="number"
                                            size="small"
                                            value={data.packaged_width}
                                            onChange={event => setData({ ...data, packaged_width: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.packaged_width)}
                                            helperText={formErrors.packaged_width}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Tiefe"
                                            type="number"
                                            size="small"
                                            value={data.packaged_depth}
                                            onChange={event => setData({ ...data, packaged_depth: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.packaged_depth)}
                                            helperText={formErrors.packaged_depth}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Gewicht"
                                            type="number"
                                            size="small"
                                            value={data.packaged_weight}
                                            onChange={event => setData({ ...data, packaged_weight: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.packaged_weight)}
                                            helperText={formErrors.packaged_weight}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {page === 3 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Select
                                            labelId="select-status"
                                            fullWidth
                                            required
                                            size="small"
                                            value={data.seasonal ? 2 : 1}
                                            onChange={event => setData({ ...data, seasonal: event.target.value === 2 })}
                                        >
                                            <MenuItem value={1}>Kein Saisonartikel</MenuItem>
                                            <MenuItem value={2}>Saisonartikel</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Select
                                            labelId="select-status"
                                            fullWidth
                                            required
                                            size="small"
                                            value={data.merch ? 1 : 2}
                                            onChange={event => setData({ ...data, merch: event.target.value === 1 })}
                                        >
                                            <MenuItem value={1}>Merchandise</MenuItem>
                                            <MenuItem value={2}>Kein Merchandise</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DesktopDatePicker
                                            label="Produktionsdatum"
                                            inputFormat="DD.MM.YYYY"
                                            value={data.production_date}
                                            onChange={(value: any) => setData({ ...data, production_date: value || undefined })}
                                            renderInput={(params: any) => <TextField size="small" fullWidth {...params} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Bestand (Gesamtzahl)"
                                            type="number"
                                            size="small"
                                            value={data.amount_total}
                                            onChange={event => setData({ ...data, amount_total: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.amount_total)}
                                            helperText={formErrors.amount_total}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Stück</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Anzahl in Verpackung"
                                            type="number"
                                            size="small"
                                            value={data.amount_per_package}
                                            onChange={event => setData({ ...data, amount_per_package: parseFloat(event.target.value) })}
                                            error={Boolean(formErrors.amount_per_package)}
                                            helperText={formErrors.amount_per_package}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Stück</InputAdornment>,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SelectPackagingType
                                            onChange={packagingType => setData({ ...data, packaging_type: packagingType })}
                                            value={data.packaging_type?.id}
                                            error={formErrors.packaging_id}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SelectTags
                                            onChange={tags => setData({ ...data, tags })}
                                            values={data.tags?.map(tag => tag.id!) ?? []}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Kleidung"
                                            size="small"
                                            multiline
                                            rows={4}
                                            maxRows={8}
                                            value={data.clothing_sizes}
                                            onChange={event => setData({ ...data, clothing_sizes: event.target.value })}
                                            error={Boolean(formErrors.clothing_sizes)}
                                            helperText={formErrors.clothing_sizes}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Beschreibung"
                                            size="small"
                                            multiline
                                            rows={6}
                                            maxRows={10}
                                            value={data.description}
                                            onChange={event => setData({ ...data, description: event.target.value })}
                                            error={Boolean(formErrors.description)}
                                            helperText={formErrors.description}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {page === 4 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FileUploadButton
                                            onChange={files => setData({ ...data, files })}
                                            files={data.files}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {page === 5 && (
                                <Grid container spacing={2}>
                                    <Fade in={Object.keys(formErrors).length > 0} unmountOnExit>
                                        <Grid item xs={12}>
                                            <Alert severity="error" variant="standard">
                                                <AlertTitle>Fehler</AlertTitle>

                                                <ul style={{ paddingLeft: 0 }}>
                                                    {Object.keys(formErrors).map((fieldName: string) => (
                                                        <li key={fieldName}>{formErrors[fieldName]}</li>
                                                    ))}
                                                </ul>
                                            </Alert>
                                        </Grid>
                                    </Fade>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" alignContent="center">
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>Allgemein</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => setPage(0)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell>{data.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>ZDF-ID</TableCell>
                                                                <TableCell>{data.zdf_id}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Indoor</TableCell>
                                                                <TableCell>{data.indoor ? 'Ja' : 'Nein'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>{data.active ? 'Online' : 'Offline'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Kategorie</TableCell>
                                                                <TableCell>{data.category?.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Programm</TableCell>
                                                                <TableCell>{data.program?.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Kunde</TableCell>
                                                                <TableCell>{data.tenant?.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{ "& td": { border: 0 } }}>
                                                                <TableCell>Lagerorte</TableCell>
                                                                <TableCell >
                                                                    <Grid container spacing={1}>
                                                                        {data.storage_areas?.map(area => (
                                                                            <Grid item key={area.name}>
                                                                                <Tooltip title={area.warehouse?.name!}>
                                                                                    <Chip size="small" label={area.name} />
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" alignContent="center">
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>Preise</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => setPage(1)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Einkaufspreis</TableCell>
                                                                <TableCell>{data.selling_price && `${data.selling_price} €`}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Produktionspreis</TableCell>
                                                                <TableCell>{data.production_price && `${data.production_price} €`}</TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{ "& td": { border: 0 } }}>
                                                                <TableCell>Mieteinheit</TableCell>
                                                                <TableCell>{data.cost_factor}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" alignContent="center">
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>Maß & Gewicht</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => setPage(2)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Maß ohne Verpackung</TableCell>
                                                                <TableCell>
                                                                    H: {data.plain_height ?? '?'}cm B: {data.plain_width ?? '?'}cm T: {data.plain_depth ?? '?'}cm
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Gewicht ohne Verpackung</TableCell>
                                                                <TableCell>{data.plain_weight && `${data.plain_weight}g`}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Maß mit Verpackung</TableCell>
                                                                <TableCell>
                                                                    H: {data.packaged_height ?? '?'}cm B: {data.packaged_width ?? '?'}cm T: {data.packaged_depth ?? '?'}cm
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{ "& td": { border: 0 } }}>
                                                                <TableCell>Gewicht mit Verpackung</TableCell>
                                                                <TableCell>{data.packaged_weight && `${data.packaged_weight}g`}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" alignContent="center">
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>Artikeldetails</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => setPage(3)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Saisonartikel</TableCell>
                                                                <TableCell>{data.seasonal ? 'Ja' : 'Nein'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Merchandise</TableCell>
                                                                <TableCell>{data.merch ? 'Ja' : 'Nein'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Produktionsdatum</TableCell>
                                                                <TableCell>{data.production_date ? moment(data.production_date).format('DD.MM.YYYY') : ''}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Bestand</TableCell>
                                                                <TableCell>{data.amount_total}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>In Verpackung</TableCell>
                                                                <TableCell>{data.amount_per_package}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Verpackungsart</TableCell>
                                                                <TableCell>{data.packaging_type?.name}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Tags</TableCell>
                                                                <TableCell>
                                                                    <Grid container spacing={1}>
                                                                        {data.tags?.map(tag => (
                                                                            <Grid key={tag.name} item>
                                                                                <Chip label={tag.name} />
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Kleidung</TableCell>
                                                                <TableCell>{data.clothing_sizes}</TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{ "& td": { border: 0 } }}>
                                                                <TableCell>Beschreibung</TableCell>
                                                                <TableCell>{data.description}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: 10 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between" alignContent="center">
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>Dokumente</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => setPage(4)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {data.files.length === 0 && (
                                                                <Typography variant="body2">Keine Dokumente gefunden</Typography>
                                                            )}
                                                            {data.files.map((file: FileFetched) => (
                                                                <TableRow key={file.name}>
                                                                    <TableCell>
                                                                        {file.id && (
                                                                            <Grid item xs={12}>
                                                                                <ImagePreview id={file.id} maxHeight="64" />
                                                                            </Grid>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container alignItems="center" justifyContent="end" spacing={2}>
                                                                            <Grid item>
                                                                                {formatBytes(file.size)}
                                                                            </Grid>
                                                                            {file.id && (
                                                                                <Grid item>
                                                                                    <OpenFile id={file.id} />
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {page < 1 ? (
                        <Button onClick={handleClose}>Abbrechen</Button>
                    ) : (
                        <Button onClick={previousPage}>Zurück</Button>
                    )}

                    {page >= steps.length - 1 ? (
                        <Button disabled={processing} variant="contained" onClick={handleSubmit}>Speichern</Button>
                    ) : (
                        <Button variant="contained" onClick={nextPage}>Weiter</Button>
                    )}
                </DialogActions>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default ArticleDialog;
