import React, { FormEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { IProgram } from '../../models/program';
import { useDispatch, useSelector } from 'react-redux';
import rest from '../../rest/rest';
import { clearFormErrors } from '../../actions/formError.actions';
import { IArticleCategory } from '../../models/article';

interface ArticleCategoryProps {
    onFinished: () => void,
    initialData?: IProgram,
    children: any,
    readOnly?: boolean
}

const defaultData: IArticleCategory = {
    name: '',
    description: '',
};

function ArticleCategoryDialog({ onFinished, initialData, children, readOnly }: ArticleCategoryProps) {
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer).errors;
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IProgram>(initialData || defaultData);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        setProcessing(true);
        dispatch(clearFormErrors());

        if (initialData) {
            const updated: any = Object.assign({}, data);

            if (updated.name === initialData.name) {
                delete updated.name;
            }

            rest.patch(`article/categories/${data.id}`, updated)
                .then(() => {
                    setOpen(false);
                    onFinished();
                })
                .catch(console.error);
            return;
        }
        rest.post('article/categories', data)
            .then(() => {
                setProcessing(false);
                onFinished();
                handleClose();
                setData(defaultData);
            })
            .catch(err => {
                setProcessing(false);
                console.error(err);
            });
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Kategorie hinzufügen</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    required
                                    label="Name"
                                    size="small"
                                    value={data.name}
                                    onChange={event => setData({ ...data, name: event.target.value })}
                                    error={Boolean(formErrors.name)}
                                    helperText={formErrors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    label="Beschreibung"
                                    size="small"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    value={data.description}
                                    onChange={event => setData({ ...data, description: event.target.value })}
                                    error={Boolean(formErrors.description)}
                                    helperText={formErrors.description}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                        {!readOnly && (
                            <Button disabled={processing} type="submit" variant="contained">Speichern</Button>
                        )}
                    </DialogActions>
                </form>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default ArticleCategoryDialog;
