import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';
import rest from '../../rest/rest';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { IArticleCategory } from '../../models/article';
import ArticleCategoryDialog from '../../components/dialogs/ArticleCategoryDialog';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import ErrorHandler from '../../components/ErrorHandler';
import DataTable from '../../components/DataTable';

function ArticleCategories() {
    const [articleCategories, setArticleCategories] = React.useState<IArticleCategory[]>();
    const [error, setError] = React.useState<string>();

    const fetchData = (): void => {
        rest.get('article/categories')
            .then(res => {
                setError(undefined);
                setArticleCategories(res.data);
            })
            .catch(err => setError(err.message));
    };

    React.useEffect(() => fetchData(), []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/verwaltung"
                                            >
                                                <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Verwaltung
                                            </Link>
                                            <Typography color="text.primary">Kategorien</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Kategorien</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ArticleCategoryDialog onFinished={fetchData}>
                                    <Button startIcon={<AddIcon />} variant="contained">Kategorie hinzufügen</Button>
                                </ArticleCategoryDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!articleCategories ? (
                                <LinearProgress />
                            ) : (
                                <DataTable
                                    rows={articleCategories}
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            flex: 0.1,
                                        },
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            flex: 0.8
                                        },
                                        {
                                            field: '_id',
                                            headerName: 'Aktionen',
                                            flex: 0.1,
                                            align: 'right',
                                            headerAlign: 'right',
                                            renderCell: ({ row }: { row: IArticleCategory }) => {
                                                const category = articleCategories.find(el => el.id === row.id);

                                                return (
                                                    <div>
                                                        <DeleteButton resource={`article/categories/${row.id}`} onDelete={fetchData} />
                                                        <ArticleCategoryDialog onFinished={fetchData} initialData={category}>
                                                            <Tooltip title="Bearbeiten">
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ArticleCategoryDialog>
                                                        <ArticleCategoryDialog onFinished={fetchData} initialData={category} readOnly>
                                                            <Tooltip title="Details">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ArticleCategoryDialog>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ArticleCategories;
