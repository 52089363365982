import { IArticleFetched } from '../models/article';
import rest from '../rest/rest';

export const getArticles = async (query?: string, tenantId?: number): Promise<IArticleFetched[]> => {
    const { data } = await rest.get(tenantId ? `article/articles?query=${query}&tenant=${tenantId}` : `article/articles?query=${query}`);

    return data;
};

export const getArticle = async (articleId: number): Promise<IArticleFetched> => {
    const { data } = await rest.get(`article/articles/${articleId}`);

    return data;
};
