import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import MiniDrawer from './components/Menu';
import Logout from './screens/Logout';
import Articles from './screens/Articles';
import Projects from './screens/projects/Projects';
import Programs from './screens/manage/Programs';
import Warehouses from './screens/manage/Warehouses';
import StorageAreas from './screens/manage/StorageAreas';
import rest from './rest/rest';
import ConfirmPrompt from './components/dialogs/ConfirmPrompt';
import ArticleCategories from './screens/manage/ArticleCategories';
import PackagingTypes from './screens/manage/PackagingTypes';
import { CircularProgress, Grid, ThemeProvider } from '@mui/material';
import Management from './screens/Management';
import ManageArticles from './screens/manage/ManageArticles';
import ManageProjects from './screens/manage/ManageProjects';
import Tags from './screens/manage/Tags';
import theme from './theme';
import { useSelector } from 'react-redux';
import StorageAreaUnits from './screens/manage/StorageAreaUnits';
import Register from './screens/Register';
import { IUser } from './models/user';
import { getUser } from './service/user.service';
import Profile from './screens/Profile';
import { loadCart } from './actions/cart.actions';
import store from './store';
import ProjectCart from './screens/projects/ProjectCart';
import Tenants from './screens/manage/Tenants';

function App() {
    const { darkMode } = useSelector((store: any) => store.uiReducer);
    const [userInfo, setUserInfo] = React.useState<any>();
    const [registered, setRegistered] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<IUser>();
    const keyCloak = React.useRef<KeycloakInstance | null>(null);

    React.useEffect(() => {
        const instance = Keycloak('/keycloak.json');

        instance.onTokenExpired = async () => {
            console.log('token expired! getting new one ...');

            await instance.updateToken(30);

            console.log('successfully got a new token');
            if (keyCloak.current) {
                rest.defaults.headers.common['Authorization'] = 'Bearer ' + keyCloak.current.token;
            }
        }

        instance.init({ onLoad: 'login-required', checkLoginIframe: false })
            .then(async (authenticated: boolean) => {
                if (!authenticated) {
                    alert('Failed to authenticate!');
                    return;
                }

                keyCloak.current = instance;

                const userInfo: any = await instance.loadUserInfo();

                setUserInfo(userInfo);
                rest.defaults.headers.common['Authorization'] = `Bearer ${keyCloak.current.token}`;

                if (instance.hasRealmRole('registered')) {
                    setRegistered(true);
                    setUser({
                        ...await getUser(instance.idTokenParsed!.sub!),
                        given_name: userInfo.given_name,
                        surname: userInfo.family_name,
                        email: userInfo.email,
                    });
                }

                store.dispatch(loadCart);
            });

        // @ts-ignore
        window.keyCloak = instance;
    }, []);

    if (!userInfo || !keyCloak.current) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    }

    if (!registered) {
        return (
            <ThemeProvider theme={theme(false)}>
                <Register keyCloak={keyCloak.current} />
            </ThemeProvider>
        );
    }

    if (!user) {
        return null;
    }

    return (
        <Router>
            <ThemeProvider theme={theme(darkMode)}>
                <MiniDrawer user={user} keyCloak={keyCloak.current}>
                    <ConfirmPrompt />
                    <Switch>
                        <Route exact path="/">
                            <Dashboard name={userInfo.name} />
                        </Route>
                        <Route path="/profil">
                            <Profile user={user} />
                        </Route>
                        <Route path="/artikel">
                            <Articles keyCloak={keyCloak.current} />
                        </Route>
                        <Route exact path="/projekte">
                            <Projects user={user} keyCloak={keyCloak.current} name={`${userInfo.given_name} ${userInfo.family_name}`} />
                        </Route>
                        <Route path="/projekte/:id">
                            <ProjectCart keyCloak={keyCloak.current} />
                        </Route>
                        <Route path="/verwaltung">
                            <Management keyCloak={keyCloak.current} />
                        </Route>
                        <Route path="/manage/artikel">
                            <ManageArticles keyCloak={keyCloak.current} />
                        </Route>
                        <Route path="/manage/projekte">
                            <ManageProjects keyCloak={keyCloak.current} />
                        </Route>
                        <Route path="/manage/programme">
                            <Programs />
                        </Route>
                        <Route path="/manage/tags">
                            <Tags />
                        </Route>
                        <Route path="/manage/kategorien">
                            <ArticleCategories />
                        </Route>
                        <Route path="/manage/lager">
                            <Warehouses />
                        </Route>
                        <Route path="/manage/lagerflächen">
                            <StorageAreas />
                        </Route>
                        <Route path="/manage/lagerflächen-einheiten">
                            <StorageAreaUnits />
                        </Route>
                        <Route path="/manage/verpackungsarten">
                            <PackagingTypes />
                        </Route>
                        <Route path="/manage/kunden">
                            <Tenants />
                        </Route>
                        <Route path="/logout">
                            <Logout keyCloak={keyCloak.current!} />
                        </Route>
                        <Route>
                            Diese Seite wurde nicht gefunden.
                        </Route>
                    </Switch>
                </MiniDrawer>
            </ThemeProvider>
        </Router>
    );
}

export default App;
