import { createTheme } from '@mui/material';

const theme = (darkMode: boolean) => createTheme({
    palette: {
        mode: darkMode ? 'dark' : 'light',
        primary: {
            main: '#313131',
            light: '#6d6d6d',
            dark: '#3b3b3b',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            light: '#718792',
            dark: '#3b3b3b',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif;',
    },
        // shape: {
    //     borderRadius: 8,
    // },
    // mixins: {
    //     toolbar: {
    //         minHeight: 48,
    //     },
    // },
    // overrides: {
    //     MuiSlider: {
    //         thumb: {
    //             color: '#D3021F',
    //         },
    //         track: {
    //             color: '#D3021F',
    //         },
    //         rail: {
    //             color: '#D3021F',
    //         },
    //         disabled: {
    //             color: 'grey',
    //         },
    //     },
    //     MuiTypography: {
    //         body2: {
    //             fontSize: 'small'
    //         }
    //     },
    //     MuiTooltip: {
    //         tooltip: {
    //             fontSize: '0.9em',
    //         },
    //     },
    // }
});

export default theme;
